/* these have to be set of offset all the grid stuff wrappped around header */
.header {
	/* the z-20 combats the axpert advice page z-10s on the articles */
	@apply z-20 flex items-center justify-between self-start;
}

.nav-is-visible .nav-pages {
	visibility: visible;
}

.nav-pages a {
	@apply block gap-8 text-4xl xl:rounded-3xl xl:px-7 xl:py-3 xl:text-lg;
}

.nav-pages a:hover {
	@apply text-sky-800 xl:bg-ghost-white;
}

/* nav variant */
.nav-dark-variant.header {
	@apply bg-dark-nav-style-gradient;
}

@media screen(xl) {
	.nav-dark-variant.header .nav-pages a {
		@apply text-white;
	}
	.nav-dark-variant.header .nav-pages a:hover {
		@apply bg-white bg-opacity-15 text-white;
	}
}

/* this is the slideout portion of the nav */

.nav-drawer {
	@apply pointer-events-auto absolute left-0 top-0 flex h-full min-h-0 w-full	basis-full translate-x-full flex-col justify-center bg-white px-8 transition-transform will-change-transform xl:w-96;
	contain: paint;
	height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	z-index: -1;
	transition: transform 0.3s;
	/* visible/invisible will need to be here for proper accessibility */
}

.nav-is-visible .nav-drawer {
	/* visible/invisible toggle is so that the links inside the tab auto add or remove themselves from tabbing order based on if the nav is open or closed */
	/* visibility: visible; */
	backdrop-filter: blur(10px);
	transition: transform 0.3s;
	transform: translateX(0%);
}

@media screen(xl) {
	.nav-drawer {
		@apply static flex h-auto w-auto flex-row justify-end bg-transparent pt-0;
		transform: unset;
	}
}

/* the following is a working solution for accesibility */
.nav-pages {
	@apply invisible;
}

@media screen(xl) {
	.nav-pages {
		@apply visible;
	}
}

.nav-social {
	@apply invisible;
}

.nav-is-visible .nav-social {
	visibility: visible;
}

@media screen(lg) {
	.nav-social {
		@apply visible;
	}
}

@media screen(xl) {
	.nav-drawer {
		@apply p-0;
	}
}

.fixed-header {
	@apply fixed z-10 w-full backdrop-blur-lg;
	/* forces Safari to use GPU */
	transform: translate3d(0, 0, 0, 0);
	/* tells Safari to use less optimizations when rendering */
	will-change: backdrop-filter;
}
