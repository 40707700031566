/* hamburger x span */
.is-visible .header__menu-icon {
	@apply bg-transparent delay-0;
}

.is-visible .header__menu-icon:before {
	@apply mt-0 rotate-45 transform;
	transition-delay: 0s, 0.2s;
}

.is-visible .header__menu-icon:after {
	@apply mt-0 -rotate-45 transform;
	transition-delay: 0s, 0.2s;
}

.header__menu-btn {
	@apply h-8 w-8 bg-transparent;
}

.header__menu-btn:hover .header__menu-icon {
	@apply bg-sky-800;
}

.header__menu-btn:hover .header__menu-icon:before,
.header__menu-btn:hover .header__menu-icon:after {
	@apply bg-sky-800;
}

/* hamburger / x menu button */
.header__menu-icon {
	/* pointer events none required to avoid event toggle issues in js */
	@apply pointer-events-none w-6 outline-none;
	transition-duration: 0s;
	transition-delay: 0.2s;
	transition-property: transform;
	height: 3px;
}

.header__menu-icon:before,
.header__menu-icon:after {
	@apply absolute block w-full;
	height: 3px;
	content: '';
}

.header__menu-icon:before {
	margin-top: -8px;
	transition-property: margin, transform;
	transition-duration: 0.2s;
	transition-delay: 0.2s, 0s;
}

.header__menu-icon:after {
	margin-top: 9px;
	transition-property: margin, transform;
	transition-duration: 0.2s;
	transition-delay: 0.2s, 0s;
}

/* this class has to be added separately so that it doesn't happen until after first click on the button */
.header__menu-btn--transition {
	@apply h-8 w-8;
	transition: all 0.4s ease;
}
